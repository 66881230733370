import {AxiosResponse} from "axios";
import {HttpService} from "../../gecsevar_framework/services/HttpService";

class EventCalenderService extends HttpService {

    constructor() {
        super()
    }

    getEvents(year: number, month: number, callback: (status: number, resp: AxiosResponse) => void) {
        //this.httpClient.get("http://127.0.0.1:9090/calendar/"+ year +"/" + month, {
        this.httpClient.get("https://api.carserviceerp.softlog.hu/calendar/" + year + "/" + month, {
            params: {}
        })
            .then((resp: AxiosResponse) => {
                callback(resp.status, resp)
            })
            .catch(function (error: any) {
                // handle error
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    // console.log(error.response.data);
                    // console.log(error.response.status);
                    // console.log(error.response.headers);
                    callback(error.config.status, error.response.data["result_text"])
                    return
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
                //callback(error.config.status, "Ismeretlen hiba")
            })
    }

    getJewishCalendar(year: number, month: number, callback: (status: number, resp: AxiosResponse) => void) {
        //this.httpClient.get("http://127.0.0.1:9090/calendar/"+ year +"/" + month, {
        this.httpClient.get("https://api.carserviceerp.softlog.hu/jewish_calendar/" + year + "/" + month, {
            params: {}
        })
            .then((resp: AxiosResponse) => {
                callback(resp.status, resp)
            })
            .catch(function (error: any) {
                // handle error
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    // console.log(error.response.data);
                    // console.log(error.response.status);
                    // console.log(error.response.headers);
                    callback(error.config.status, error.response.data["result_text"])
                    return
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
                //callback(error.config.status, "Ismeretlen hiba")
            })
    }
}

const eventCalenderService = new EventCalenderService();
export default eventCalenderService;