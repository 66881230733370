import React, {RefObject, useEffect, useRef, useState} from "react";
import {Box, Fade, Grid} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import articleService from "../services/ArticleService";
import ArticleModel from "../models/ArticleModel";
import DOMPurify from "dompurify";
import {gecsevarConfig} from "../pages/MainPage";
import {plainToInstance} from "class-transformer";

let data = new ArticleModel();

// CREDITS: https://mtsknn.fi/blog/react-links-in-html/
function useLinkClickHandlers(ref: RefObject<HTMLDivElement>) {
    const navigate = useNavigate()

    useEffect(() => {

        if (!ref.current) return

        const links = ref.current.querySelectorAll('a')
        links.forEach((link) => link.addEventListener('click', handleLinkClick))

        // clean up
        return () => {
            links.forEach((link) => link.removeEventListener('click', handleLinkClick))
        }

        function handleLinkClick(event: MouseEvent) {
            const link = event.currentTarget as HTMLAnchorElement
            const href = link.getAttribute('href')
            const target = link.getAttribute('target')  // now: 'rel'
            const rel = link.getAttribute("rel")    // -> "noopener" must set when open in new window occurs
            const url = new URL(href || '', window.location.origin)

            let isInternalLink = url.origin === window.location.origin
            const isOpenedInSameWindow = !target || target === '_self'
            const isLeftButtonClick = event.button === 0
            const isModifierKeyPressed = event.altKey || event.ctrlKey || event.metaKey || event.shiftKey

            //if (window.location.origin === "http://localhost:3000") {
            //    // DEBUG
            //    isInternalLink = true
            //}

            if (isInternalLink && isOpenedInSameWindow && isLeftButtonClick && !isModifierKeyPressed) {
                event.preventDefault()
                navigate(url.pathname + url.search + url.hash)
            }
        }

    }, [navigate, ref]);
}

export function HtmlContent({html}: { html: string }) {
    const ref = useRef<HTMLDivElement>(null)
    useLinkClickHandlers(ref)

    return <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(html, {ADD_ATTR: ['target']})}} ref={ref}/>
}

type ContentProps = {
    path?: string
}

export function Content(props: ContentProps) {

    const ref = useRef<HTMLDivElement>(null)
    useLinkClickHandlers(ref)

    const param = useParams();
    const [loaded, setLoaded] = useState(false)
    const scrollToTop = () => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    };

    useEffect(() => {
        setLoaded(false)    // Force rerender page
        if (process.env.REACT_APP_CONTENT_TYPE === "static") {
            data = plainToInstance(ArticleModel, {
                id: 0,
                published: true,
                path: "",
                categories: "",
                categoriesArray: [],
                creator: "",
                createTime: new Date(),
                title: "Title",
                cardImageUrl: "",
                bannerImageUrl: "",
                description: "",
                content: gecsevarConfig.getContent(param["path"]),
            })
            setLoaded(true)
            scrollToTop()
        } else {
            articleService.get(param["path"] === undefined ? props.path!! : param["path"], (resp) => {
                data = ArticleModel.fromJSON(resp.data)
                // https://developer.chrome.com/docs/lighthouse/best-practices/external-anchors-use-rel-noopener/
                setLoaded(true)
                scrollToTop()
            })
        }
    }, [param])

    if (!loaded) {
        return (<Box>
                Page loading...
            </Box>
        )
    } else {
        return <Grid container margin={0} spacing={0} direction={"row"} justifyContent={"center"} alignItems={"stretch"}>
            <Grid item xs={0} md={0} lg={0} xl={1}>
            </Grid>
            <Grid item xs={11} md={8} lg={8} xl={6}>
                <Fade in={loaded} timeout={1000}>
                    <Box sx={{p: 2}}>
                        {data.bannerImageUrl.length !== 0 ?
                            <img alt="" src={data.bannerImageUrl} height={50}/>
                            :
                            <></>
                        }
                        <HtmlContent html={data.content}/>
                    </Box>
                </Fade>
            </Grid>
            {gecsevarConfig.getContentSideBox()}
        </Grid>
    }
}
