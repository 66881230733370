import {Helmet} from "react-helmet-async";

type SEOProps = {
    title: string
    description: string
    name: string
    type: string
}
export default function SEO(props: SEOProps) {
    return (
        <Helmet>
            { /* Standard metadata tags */}
            <title>{props.title}</title>
            <meta name='description' content={props.description}/>
            { /* End standard metadata tags */}
            { /* Facebook tags */}
            <meta property="og:type" content={props.type}/>
            <meta property="og:title" content={props.title}/>
            <meta property="og:description" content={props.description}/>
            { /* End Facebook tags */}
            { /* Twitter tags */}
            <meta name="twitter:creator" content={props.name}/>
            <meta name="twitter:card" content={props.type}/>
            <meta name="twitter:title" content={props.title}/>
            <meta name="twitter:description" content={props.description}/>
            { /* End Twitter tags */}
        </Helmet>
    )
}

/*
     {/* General tags
<title>{metaTitle}</title>
<meta name="image" content={image} />
<meta name="description" content={metaDescription} />

{/* OpenGraph tags
<meta property="og:title" content={metaTitle} />
<meta property="og:type" content={post ? `article` : `website`} />
<meta property="og:url" content={metaUrl} />
<meta property="og:image" content={image} />
<meta property="og:description" content={metaDescription} />

{/* Twitter Card tags
<meta name="twitter:card" content="summary" />
<meta name="twitter:creator" content="{site.siteMetadata.author}" />
<meta name="twitter:title" content={metaTitle} />
<meta name="twitter:image" content={image} />
<meta name="twitter:description" content={metaDescription} />
 */