class ArticleCategoryModel {

    id: number = 0
    name: string = ""
    active: boolean = true
    description: string = ""
    articleId: number | null = null
    articleTitle: string | null = null
}

export default ArticleCategoryModel