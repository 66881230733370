import {Box, Collapse, Drawer, Link, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar} from "@mui/material";
import React, {useEffect} from "react";
import {gecsevarConfig} from "../pages/MainPage";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import {ArticleListPath, ContentPath} from "./ContentRoutes";
import {DrawerMenuLink, DrawerMenuModel} from "../models/DrawerMenuModel";
import {useResizeDetector} from "react-resize-detector";

type MenuItemProps = {
    item: DrawerMenuModel
    selectionId: string
    setSelectionId: (id: string) => void
    setDrawerState: (state: boolean) => void
    collapseOpen: Map<string, boolean>
    setCollapseOpen: (state: Map<string, boolean>) => void
}

function MenuItem(props: MenuItemProps) {

    const [open, setOpen] = React.useState(false)

    useEffect(() => {
        setOpen(props.collapseOpen.get(props.item.id)!!)
    }, []);

    if (props.item.level === 'collapse') {
        return <Box width={'inherit'}>
            <Link key={props.item.id} style={{textDecoration: 'none'}}
                  onClick={() => {
                      const temp = props.collapseOpen
                      const val = temp.get(props.item.id) === undefined ? false : temp.get(props.item.id)!
                      temp.set(props.item.id, !val)
                      setOpen(!val)
                      props.setCollapseOpen(temp)
                  }}
            >
                <ListItem disablePadding key={props.item.id}>
                    <ListItemButton selected={props.selectionId === props.item.id} sx={{pl: 0,}}>
                        <ListItemIcon>{}</ListItemIcon>
                        <ListItemText>{props.item.label}</ListItemText>
                        {open ? <ExpandLess/> : <ExpandMore/>}
                    </ListItemButton>
                </ListItem>
            </Link>
            <Collapse key={"collapse" + props.item.id} in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {gecsevarConfig?.getSubMenuFor(props.item.id).map((value, index) => (
                        <MenuListItem key={index} item={value} selectionId={props.selectionId} isSubmenu={true}
                                      setSelectionId={props.setSelectionId} setDrawerState={props.setDrawerState}
                        />
                    ))}
                </List>
            </Collapse>
        </Box>
    } else {
        return (
            <MenuListItem key={props.item.id} item={props.item} selectionId={props.selectionId} isSubmenu={false}
                          setSelectionId={props.setSelectionId} setDrawerState={props.setDrawerState}
            />
        )
    }
}

type MenuListItemProps = {
    item: DrawerMenuModel
    selectionId: string
    isSubmenu: boolean
    setSelectionId: (id: string) => void
    setDrawerState: (state: boolean) => void
}

function MenuListItem(props: MenuListItemProps) {

    const navigate = useNavigate()

    return (
        <Box width={'inherit'}>
            <Link key={props.item.id} style={{textDecoration: 'none'}} onClick={() => {

                switch (props.item.linkType) {
                    case DrawerMenuLink.NONE:
                        break;
                    case DrawerMenuLink.ARTICLE:
                        navigate(ContentPath + props.item.articlePath)
                        break;
                    case DrawerMenuLink.CATEGORY:
                        // navigate to /{path}/{id} HTML page
                        navigate(ArticleListPath + props.item.articleCategoryName)
                        break;
                }
                props.setSelectionId(props.item.id)
                props.setDrawerState(false)
            }}>
                <ListItem disablePadding key={props.item.id} sx={{}}>
                    <ListItemButton selected={props.selectionId === props.item.id} sx={{pl: 0,}}>
                        <ListItemIcon>{}</ListItemIcon>
                        <ListItemText sx={{marginLeft: props.isSubmenu ? 2 : 0}}>{props.item.label}</ListItemText>
                    </ListItemButton>
                </ListItem>
            </Link>
        </Box>
    )
}

type DrawerMenuProps = {
    drawerState: boolean
    selectionId: string
    setDrawerState: (value: boolean) => void
    setSelectionId: (value: string) => void
}

export function DrawerMenu(props: DrawerMenuProps) {

    const [collapseStateMap, setCollapseStateMap] = React.useState(new Map<string, boolean>())
    const {width, height, ref} = useResizeDetector()

    return <Drawer
        anchor={'right'}
        open={props.drawerState}
        onClose={(_: React.SyntheticEvent<{}, Event>) => {
            props.setDrawerState(false)
        }}
        sx={{flexShrink: 1}}
        SlideProps={{
            style: {}
        }}
        PaperProps={{
            sx: {},
            style: {}
        }}
    >
        <Toolbar/>
        <Box ref={ref}
             sx={{
                 color: 'white',
                 background: gecsevarConfig.getTheme().getMenuBackground(),
                 height: '100%',
                 width: 'inherit',
                 transition: "width 2s"
             }}
        >
            <List disablePadding sx={{width: 'inherit'}}>
                <ListItem sx={{py: 2, px: 3}}>
                    <ListItemText>{gecsevarConfig.getMenuTitle()}</ListItemText>
                </ListItem>
                {gecsevarConfig?.getMenuNavigation().map((value, _) => (
                    <MenuItem key={value.id} item={value} selectionId={props.selectionId} setSelectionId={props.setSelectionId}
                              setDrawerState={props.setDrawerState}
                              collapseOpen={collapseStateMap}
                              setCollapseOpen={setCollapseStateMap}
                    />
                ))}
            </List>
        </Box>
    </Drawer>
}