export enum DrawerMenuLink {
    NONE = "NONE",
    ARTICLE = "ARTICLE",
    CATEGORY = "CATEGORY",
}

export class DrawerMenuModel {

    id: string = ""
    label: string = ""
    level: string = ""
    linkType = DrawerMenuLink.NONE
    articleId?: number
    articlePath?: string = ""
    articleTitle: string = ""
    articleCategoryId?: number
    articleCategoryName: string = ""
}