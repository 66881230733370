import {baseUrlAdmin, HttpService} from "./HttpService";
import {AxiosResponse} from "axios";

class ArticlCategoryeService extends HttpService {

    constructor() {
        super()
    }

    get(name: string, callback: (status: number, resp: AxiosResponse) => void): Object {
        this.httpClient.get(baseUrlAdmin + "article-category", {
            params: {
                name: name
            }
        })
            .then((resp: AxiosResponse) => {
                callback(resp.status, resp)
            })
            .catch(function (error: any) {
                // handle error
                console.log('hiba' + error)
            })

        return ""
    }
}

const articlCategoryeService = new ArticlCategoryeService()
export default articlCategoryeService