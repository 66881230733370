import {alpha, AppBar, Box, Button, Fade, IconButton, InputBase, Link, Toolbar, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import SearchIcon from '@mui/icons-material/Search';
import {styled} from "@mui/material/styles";
import Hamburger from 'hamburger-react'
import {useNavigate} from "react-router-dom";
import {gecsevarConfig, useTheme} from "../pages/MainPage";
import {DrawerMenu} from "./DrawerMenu";
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import {HEADER_COMPONENT} from "../config/GecsevarConfig";

const Search = styled('div')(({theme}) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({theme}) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({theme}) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

export function HeaderBar() {

    const {theme, toggleTheme} = useTheme()
    const navigate = useNavigate()
    const [loaded, setLoaded] = useState(false)
    const [search, setSearch] = React.useState("");

    const [drawerState, setDrawerState] = useState(false)
    const [selectionId, setSelectionId] = useState("")

    useEffect(() => {
        setLoaded(true)
    }, [])

    return (<Box sx={{display: 'flex'}}>
            <Fade key={0} in={loaded}>
                <AppBar sx={{
                    color: 'white',
                    background: gecsevarConfig.getTheme().getHeaderBarBackground(),
                    backdropFilter: gecsevarConfig.getTheme().getHeaderBarBackdropFilter(),
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                        position="fixed" elevation={1}
                >
                    <Toolbar>
                        <Link sx={{display: {xs: 'block', sm: 'none'}}}>
                            <img alt="Home" src={gecsevarConfig.getTheme().getHeaderLogo("xs")} height={35} onClick={() => {
                                navigate("/")
                            }}/>
                        </Link>
                        <Link sx={{display: {xs: 'none', sm: 'block'}}}>
                            <img alt="Home" src={gecsevarConfig.getTheme().getHeaderLogo("sm")} height={35} onClick={() => {
                                navigate("/")
                            }}/>
                        </Link>
                        <Typography marginLeft={1} variant="h6" noWrap component="div"
                                    sx={{color: 'white', flexGrow: 1, display: {xs: 'none', sm: 'none'}}}>
                            ---INVISIBLE---
                        </Typography>
                        <Box flexGrow={2}>
                        </Box>
                        {gecsevarConfig.getHeaderButtons().map((value, index) => (
                            <Button key={index} color={"secondary"} variant={"outlined"} sx={{display: {xs: 'block', sm: 'block'}}} onClick={() => {
                                navigate(value.route)
                                setDrawerState(false)
                            }}>
                                {value.title}
                            </Button>
                        ))
                        }
                        <Search sx={{marginLeft: 1, display: gecsevarConfig.isHeaderComponentVisible(HEADER_COMPONENT.SEARCH) ? 'block' : 'none'}}>
                            <SearchIconWrapper>
                                <SearchIcon/>
                            </SearchIconWrapper>
                            <StyledInputBase
                                placeholder="Search…"
                                inputProps={{'aria-label': 'search'}}
                                value={search}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setSearch(event.target.value);
                                }}
                                onKeyUp={(event) => {
                                    if (event.key === 'Enter') {
                                        console.log("enter press")
                                    }
                                }}
                            />
                        </Search>
                        <Box sx={{marginLeft: 2, display: gecsevarConfig.isHeaderComponentVisible(HEADER_COMPONENT.COLOR_MODE) ? 'block' : 'none'}}>
                            {theme === 'light' ?
                                <LightModeIcon onClick={() => {
                                    toggleTheme()
                                }}/>
                                :
                                <DarkModeIcon onClick={() => {
                                    toggleTheme()
                                }}/>
                            }
                        </Box>
                        <IconButton size="large" edge="start" color="inherit" aria-label="open drawer" sx={{marginLeft: 1}}
                                    onClick={() => {
                                        setDrawerState(!drawerState)
                                    }}
                        >
                            <Hamburger toggled={drawerState} toggle={setDrawerState} easing="ease-in" direction={"right"} rounded/>
                        </IconButton>
                    </Toolbar>
                </AppBar>
            </Fade>
            <DrawerMenu drawerState={drawerState} selectionId={selectionId} setDrawerState={setDrawerState} setSelectionId={setSelectionId}/>
        </Box>
    )
}
