import {Box, Paper, Toolbar} from "@mui/material";
import React from "react";
import {Route, Routes} from "react-router-dom";
import {Content} from "./Content";
import {ArticleListPage, ArticleListPageType} from "./ArticleListPage";
import {gecsevarConfig} from "../pages/MainPage";

export const ContentPath =
    process.env.REACT_APP_CONTENT_PATH !== undefined && process.env.REACT_APP_CONTENT_PATH.length !== 0
        ?
        process.env.REACT_APP_CONTENT_PATH : "/content/"
export const ArticleListPath = "/article-list/"

type  ContentProps = {}

export function ContentRoutes(_: ContentProps) {

    return (<Box component={"main"} sx={{
            p: 0,
            background: gecsevarConfig.getTheme().getContainerBackground(),
            backgroundPosition: 'center',
            backgroundAttachment: 'fixed',
            width: 'maxWidth',
        }}>
            <Toolbar sx={{marginTop: 1}}/>
            <Box marginTop={0} marginBottom={0} sx={{
                width: 'inherit',
                p: 0,
                background: gecsevarConfig.getTheme().getContainerInnerGridBackground(),
                m: 0,
            }}>
                <Paper elevation={1} sx={{
                    p: "0px",
                    marginTop: 0,
                    marginBottom: 0,
                    borderRadius: 0,
                    backgroundColor: gecsevarConfig.getTheme().getContainerInnerPaperBackground()
                }}>
                    <Routes>
                        <Route key={"A1"} path={"/"} element={gecsevarConfig.getDashboard()}></Route>
                        <Route key={"A2"} path={`${ContentPath}:path`} element={<Content key={1}/>}></Route>
                        <Route key={"A5"} path={`${ArticleListPath}:category`} element={<ArticleListPage key={1} type={ArticleListPageType.page}
                                                                                                         category={gecsevarConfig.getDefaultArticleListCategory()}/>}></Route>
                        {gecsevarConfig.getHeaderButtons().map((value, index) => (
                            <Route key={index} path={value.route} element={gecsevarConfig.getHeaderButtonElementBy(value.element)}></Route>
                        ))
                        }
                    </Routes>
                </Paper>
            </Box>
        </Box>
    )
}
