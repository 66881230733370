import {baseUrlAdmin, HttpService} from "./HttpService";
import {AxiosResponse} from "axios";

class ArticleService extends HttpService {

    constructor() {
        super()
    }

    get(path: string, callback: (resp: AxiosResponse) => void) {
        this.httpClient.get(baseUrlAdmin + "article", {
            params: {
                path: path
            }
        })
            .then((resp: AxiosResponse) => {
                callback(resp)
            })
            .catch(function (error: any) {
                // handle error
                console.log('hiba' + error)
            })
    }

    getHead(id: number, callback: (status: number, resp: AxiosResponse) => void) {
        this.httpClient.get(baseUrlAdmin + "article/head", {
            params: {
                id: id
            }
        })
            .then((resp: AxiosResponse) => {
                callback(resp.status, resp)
            })
            .catch(function (error: any) {
                // handle error
                console.log('hiba' + error)
            })
    }
}

const articleService = new ArticleService()
export default articleService