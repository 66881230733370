import {plainToClass} from "class-transformer";

class ArticleModel {
    id: number = 0
    published: boolean = false
    path: string = ""
    categories = ""
    categoriesArray: Array<string> = []
    creator: string = ""
    createTime: Date = new Date()
    title: string = ""
    cardImageUrl: string = ""
    bannerImageUrl: string = ""
    description: string = ""
    content: string = ""

    static fromJSON(data: string) {
        let temp = plainToClass(ArticleModel, data)
        if (temp.categories.length === 0) {
            temp.categoriesArray.push("article") // default
            // When listing articles, then its top content type:  "article-list-head"
        } else {
            temp.categoriesArray = temp.categories.split(',')
        }

        return temp
    }

    toJSON(): string {
        this.categories = this.categoriesArray.toString()
        const {categoriesArray: _, ...props} = this;
        return JSON.stringify(props)
    }

}

export default ArticleModel