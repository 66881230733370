import {HeaderBar} from "../components/HeaderBar";
import {Box, CircularProgress, CssBaseline, Paper, Stack, Theme, ThemeProvider, Typography} from "@mui/material";
import React, {createContext, useContext, useEffect, useState} from "react";
import {ContentRoutes} from "../components/ContentRoutes";
import SEO from "../components/SEO";

import {GVHuConfig} from "../config/gecsevar.hu/GVHuConfig";
import {MadregotConfig} from "../config/madregot.hu/MadregotConfig";
import {GecsevarConfig, HTML_HEAD_META} from "../config/GecsevarConfig";
import {GVHuTheme} from "../config/gecsevar.hu/GVHuTheme";
import {scheiberTheme} from "../config/scheiber.hu/ScheiberTheme";
import {ScheiberConfig} from "../config/scheiber.hu/ScheiberConfig";

export let gecsevarConfig: GecsevarConfig
export let gecsevarTheme: Theme

if (process.env.REACT_APP_SITE == "gecsevar") {
    gecsevarTheme = GVHuTheme('dark')
    gecsevarConfig = new GVHuConfig()
} else if (process.env.REACT_APP_SITE == "madregot") {
    gecsevarTheme = scheiberTheme()
    gecsevarConfig = new MadregotConfig()
} else if (process.env.REACT_APP_SITE == "scheiber") {
    gecsevarTheme = scheiberTheme()
    gecsevarConfig = new ScheiberConfig()
}
/*else if (process.env.REACT_APP_SITE == "zemez") {
    gecsevarTheme = zemezTheme('dark')
    gecsevarConfig = new ZemezHuConfig()
} else if (process.env.REACT_APP_SITE == "gctrans") {
    gecsevarTheme = gctransTheme('dark')
    gecsevarConfig = new GctransHuConfig()
} else if (process.env.REACT_APP_SITE == "zorcar") {
    gecsevarTheme = zorcarTheme('dark')
    gecsevarConfig = new ZorcarHuConfig()
}
*/

type ThemeContextType = {
    theme: string,
    toggleTheme: () => void
}
const ThemeContext = createContext<ThemeContextType | undefined>(undefined)

export function useTheme() {
    const context = useContext(ThemeContext)
    if (context === undefined) {
        throw new Error('useTheme must be used within ThemeProvider')
    }
    return context
}

export function MainPage() {

    const [theme, setTheme] = useState<string>("dark");
    const toggleTheme = () => {
        setTheme((theme) => (theme === 'light' ? 'dark' : 'light'))
        gecsevarTheme = scheiberTheme()//gecsevarHuTheme(theme === "light" ? 'dark' : 'light')
    }
    const [gecsevarConfigInit, setGecsevarConfigInit] = useState(false)

    useEffect(() => {
        gecsevarConfig.initialize(() => {
            // Site config must be loaded to load the menu and site structure!
            setGecsevarConfigInit(true)
        })
        if (process.env.REACT_APP_SITE == "scheiber") {
            setTheme('light')
        }

    }, [])

    if (!gecsevarConfigInit) {
        return (
            <Box sx={{height: '100vh', width: '100%'}}>
                <Paper elevation={4} sx={{
                    p: 0,
                    m: 0,
                }} component={Stack} direction="column" justifyContent="center">
                    <Box sx={{display: 'flex', justifyContent: 'center', p: 4, color: 'black'}}>
                        <CircularProgress thickness={3} size={50}
                                          sx={{
                                              '.MuiCircularProgress-colorPrimary': {
                                                  left: '43%',
                                                  position: 'absolute',
                                                  top: '44vh',
                                                  color: 'primary.main'
                                              }
                                          }}
                        />
                        <Typography variant={"h4"} fontWeight={"normal"} marginLeft={2}>Loading site config...</Typography>
                    </Box>
                </Paper>
            </Box>
        )
    } else {
        return (
            <ThemeContext.Provider value={{theme, toggleTheme}}>
                <SEO
                    title={gecsevarConfig.getHtmlHead(HTML_HEAD_META.META_TITLE)}
                    description={gecsevarConfig.getHtmlHead(HTML_HEAD_META.META_DESCRIPTION)}
                    name={gecsevarConfig.getHtmlHead(HTML_HEAD_META.META_NAME)}
                    type="article"/>
                <ThemeProvider theme={gecsevarTheme}>
                    <CssBaseline/>
                    <Box sx={{height: "100vh", display: 'flex', flexFlow: 'column'}}>
                        <Box sx={{flexShrink: 0}}><HeaderBar/></Box>
                        <Box sx={{flexGrow: 1}}><ContentRoutes/></Box>
                        <Box sx={{flexShrink: 0, width: '100%', color: 'white', backgroundColor: 'primary.main',}}>
                            {gecsevarConfig.getFooterContent()}
                        </Box>
                        {gecsevarConfig.getCopyrightContent()}
                    </Box>
                </ThemeProvider>
            </ThemeContext.Provider>
        )
    }
}