import React, {useEffect, useState} from "react";
import {Alert, Box, Button, FormGroup, MenuItem, Snackbar, TextField, Typography} from "@mui/material";
import {Field, Form, Formik} from "formik";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import ReCAPTCHA from "react-google-recaptcha";
import {object, string} from "yup";
import {instanceToInstance} from "class-transformer";
import MessageModel, {MessageType} from "../../models/scheiber.hu/MessageModel";
import SpecialistModel from "../../models/scheiber.hu/SpecialistModel";
import appointmentService from "../../services/scheiber.hu/AppointmentService";
import sendMessageService from "../../services/scheiber.hu/SendMessageService";
import {GVSelect, GVTextField} from "@gecsevar.hu/gecsevar-formik-fields";
import {SchoolClasses} from "../../models/scheiber.hu/SchoolClasses";

const recaptchaRef = React.createRef<ReCAPTCHA>()

const formValidationSchema = object({
    messageModel: object({
        senderName: string().required('Kötelező mező!'),
        senderContactEmail: string().email('Nem megfelelő e-mail formátum').required('Kötelező mező!'),
        senderContactMobile: string(),
        senderRelative: string(),
        senderRelativeClass: string(),
        content: string().required('Kötelező mező!')
    }),
    //mytime: date().required("Kell!"),
    //mydatetime: date().required("Kell!"),
    //mydate: date().required("Kell!"),
    /*
        mytime: string()
            .required("end time cannot be empty")
            .test("is-greater", "end time should be greater", function(value) {
                //const { start } = this.parent;
                console.log(value)
                //console.log(start)
                console.log(moment(value, "HH:mm"))
                console.log(moment("20:00", "HH:mm"))
                var egy = moment(value, "HH:mm")
                var ket = moment("20:00", "HH:mm")
                console.log(egy)
                console.log(ket)

                console.log(moment(value, "HH:mm").isSame(moment("20:00", "HH:mm")))

                return moment(value, "HH:mm").isSame(moment("20:00", "HH:mm"))
            })
     */

});

export function Uzenetkuldes() {

    const message = new MessageModel(null)

    const [loaded, setLoaded] = useState(false)
    const [specialists, setSpecialists] = useState<Array<SpecialistModel>>(new Array<SpecialistModel>())
    const [errorMsg, setErrorMsg] = useState("")
    const [open, setOpen] = useState(false)
    const [messageType, setMessageType] = useState<MessageType>(0)
    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setErrorMsg("")
        setOpen(false);
    }
    useEffect(() => {
        setLoaded(false)    // Force rerender page
        appointmentService.getSpecialists((resp) => {
            const temp: Array<SpecialistModel> = resp.data.map((value: string) => {
                return instanceToInstance(value)
            })
            setSpecialists(temp)
            setLoaded(true)
        })
    }, [])

    if (!loaded) {
        return (<Box>
                Page loading...
            </Box>
        )
    } else {
        return <Box sx={{display: 'grid', justifyContent: 'center', p: 2, backgroundColor: 'rgba(255,255,255,0.7)',}} width={"100%"}>
            <Typography variant={"h5"} fontStyle={"normal"} margin={0} align={"center"} width={"100%"} marginBottom={2}>
                Üzenetküldés
            </Typography>
            <Box>
                <TextField value={messageType}
                           label={message.getLabelBy('type')}
                           size={"small"}
                           sx={{minWidth: 'auto'}}
                           onChange={(event) => {
                               setMessageType(Number(event.target.value))
                           }}
                           select
                >
                    <MenuItem value={MessageType.Admission}>{message.getSelectItemFor(MessageType.Admission)}</MenuItem>
                    <MenuItem value={MessageType.Enrollment}>{message.getSelectItemFor(MessageType.Enrollment)}</MenuItem>
                    <MenuItem value={MessageType.StudentRelated}>{message.getSelectItemFor(MessageType.StudentRelated)}</MenuItem>
                    <MenuItem value={MessageType.Meal}>{message.getSelectItemFor(MessageType.Meal)}</MenuItem>
                    <MenuItem value={MessageType.Other}>{message.getSelectItemFor(MessageType.Other)}</MenuItem>
                </TextField>
            </Box>
            <Formik
                initialValues={{
                    messageModel: new MessageModel(messageType),
                    specialList: specialists,
//                        freeTimeList: timeList
                    mytime: new Date(),
                    mydatetime: new Date(),
                    mydate: new Date(),
                }}
                onSubmit={(values, actions) => {
                    values.messageModel.subject = values.messageModel.getSelectItemFor(values.messageModel.type)
                    actions.setSubmitting(true)
                    sendMessageService.post(JSON.stringify(values.messageModel), (status, resp) => {
                        if (status === 200) {
                            setErrorMsg("")
                            setOpen(true)
                            actions.resetForm()
                            recaptchaRef.current?.reset()
                        } else {
                            if (typeof resp === "string") {
                                setErrorMsg(resp)
                                setOpen(true)
                            }
                            recaptchaRef.current?.reset()
                        }
                        actions.setSubmitting(false);
                    })
                }}
                validationSchema={formValidationSchema}
                validateOnChange={true}    // if true, gets fucking slow ;)
                validateOnBlur={true}
                enableReinitialize={true}
            >
                {({values, handleChange}) => (
                    <Form>
                        <Box>
                            <FormGroup>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{vertical: "top", horizontal: "center"}}>
                                        <Alert onClose={handleClose} severity={errorMsg.length === 0 ? "success" : "error"} sx={{width: '100%'}}>
                                            {errorMsg.length === 0 ? "Sikeres küldés!" : errorMsg}
                                        </Alert>
                                    </Snackbar>
                                    <Box sx={{display: {sm: 'block', md: 'flex'}}} marginTop={1}>
                                        <Field key={2} name={`messageModel.senderName`} label={values.messageModel.getLabelBy("senderName")}
                                               component={GVTextField}/>
                                        <Field key={3} name={`messageModel.senderContactEmail`} label={values.messageModel.getLabelBy("senderContactEmail")}
                                               component={GVTextField}/>
                                        <Field key={4} name={`messageModel.senderContactMobile`} label={values.messageModel.getLabelBy("senderContactMobile")}
                                               component={GVTextField}/>
                                    </Box>
                                    <Box sx={{display: values.messageModel.getVisibilityBy('senderRelative') ? 'flex' : 'none'}} marginTop={1}>
                                        <Field key={5} name={`messageModel.senderRelative`} label={values.messageModel.getLabelBy("senderRelative")}
                                               component={GVTextField}/>
                                        {values.messageModel.type === MessageType.StudentRelated || values.messageModel.type === MessageType.Meal ?
                                            <Field key={6} name={`messageModel.senderRelativeClass`}
                                                   label={values.messageModel.getLabelBy("senderRelativeClass")}
                                                   component={GVSelect}>
                                                {SchoolClasses.map((key, index, _) => {
                                                    return <MenuItem key={index} value={key.value}>{key.value}</MenuItem>
                                                })
                                                }
                                            </Field>
                                            :
                                            <Field key={6} name={`messageModel.senderRelativeClass`}
                                                   label={values.messageModel.getLabelBy("senderRelativeClass")}
                                                   component={GVTextField}/>
                                        }
                                    </Box>
                                    <Box sx={{display: values.messageModel.getVisibilityBy('teacherName') ? 'flex' : 'none'}}>
                                        <Field key={1} name={`messageModel.teacherName`} label={"Tanár neve"} component={GVSelect}
                                               onChange={(e: React.ChangeEvent<any>) => {
                                                   handleChange(e)
                                               }}
                                               style={{width: "300px", margin: "5px"}}
                                        >
                                            {values.specialList &&
                                                values.specialList.map((item) => (
                                                    <MenuItem key={item.tanar_nev} value={item.tanar_nev}>{item.tanar_nev} ({item.munkakor})</MenuItem>
                                                ))
                                            }
                                        </Field>
                                    </Box>
                                    <Box sx={{display: 'flex'}} marginTop={1}>
                                        <Field name={`messageModel.content`} label={values.messageModel.getLabelBy('content')} multiline
                                               rows={4} component={GVTextField} style={{width: "400px", margin: "5px"}}/>
                                    </Box>
                                    <Box marginTop={2} marginLeft={1}>
                                        <ReCAPTCHA
                                            ref={recaptchaRef}
                                            size="normal"
                                            sitekey="6Le5i_MoAAAAANHXJjjbMdEev2rZjlCYLlGPNHdU"
                                            onChange={(token) => {
                                                if (token != null) {
                                                    values.messageModel.recaptchaToken = token
                                                }
                                            }}
                                        />
                                    </Box>
                                    <Box sx={{display: 'flex', justifyContent: 'center'}} margin={4}>
                                        <Button color={"primary"} variant={"contained"} type={"submit"}>Küldés</Button>
                                    </Box>
                                </LocalizationProvider>
                            </FormGroup>
                        </Box>
                    </Form>
                )}
            </Formik>
        </Box>
    }
}
