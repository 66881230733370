import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Box, Card, Grid, Pagination, Paper, Skeleton, Stack, Typography} from "@mui/material";
import articleListService from "../services/ArticleListService";
import ArticleModel from "../models/ArticleModel";
import {plainToInstance} from "class-transformer";
import {MiniNewsCard} from "./MiniNewsCard";
import {MaxiNewsCard} from "./MaxiNewsCard";
import {gecsevarConfig} from "../pages/MainPage";
import {HtmlContent} from "./Content";
import ArticleCategoryModel from "../models/ArticleCategoryModel";
import articleService from "../services/ArticleService";
import articleCategoryService from "../services/ArticleCategoryService";
import {AnimatePresence, motion} from "framer-motion";

export enum ArticleListPageType {
    dashboard,
    page
}

type ArticleListPageParams = {
    type: ArticleListPageType
    category: string
}

export function ArticleListPage(props: ArticleListPageParams) {

    const param = useParams()

    useEffect(() => {

    }, []);

    return (props.type === ArticleListPageType.dashboard) ?
        <ArticleListAsDashboard key={1} category={param["category"] !== undefined ? param["category"] : props.category}/>
        :
        <ArticleListAsPage key={1} category={param["category"] !== undefined ? param["category"] : props.category}/>
}

type ArticleListAsDashboardProps = {
    category: string
}

function ArticleListAsDashboard(props: ArticleListAsDashboardProps) {

    const [articles, setArticles] = React.useState(new Array<ArticleModel>())
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        articleListService.getArticlesAtPage(props.category, 1, gecsevarConfig.getItemsCountOnArticleListPage(), (status, resp) => {
            if (status === 200) {
                const temp: Array<ArticleModel> = resp.data.map((value: string) => {
                    return plainToInstance(ArticleModel, value)
                })
                setArticles(temp)
                setLoaded(true)
            }
        })
    }, [props.category]);
    return (!loaded || articles === undefined || articles.length === 0) ?
        <Stack spacing={0} margin={2}>
            <Skeleton variant={"rectangular"}>
                <Card/>
            </Skeleton>
        </Stack>
        :
        <Paper elevation={2} sx={{
            p: 1,
            m: '8px',
            position: 'center',
            height: `calc(100% - 16px)`,
            backgroundColor: gecsevarConfig.getTheme().getArticleListPageBackground(),
        }}>
            <Typography key={0} variant={"h5"} fontWeight={"bold"}>{props.category}</Typography>
            <Box key={1} style={{display: 'block', justifyContent: 'space-evenly'}}>
                {articles.map((item, index) => (
                    <div key={index} style={{position: "relative", overflow: "hidden"}}>
                        <motion.div
                            initial={{
                                opacity: 0,
                                y: 75
                            }}
                            animate={{
                                opacity: 1,
                                y: 0
                            }}
                            transition={{
                                duration: .5,
                                delay: 2 + (.3 * index)
                            }}
                        >
                            <MiniNewsCard article={item}/>
                        </motion.div>
                    </div>
                ))}
            </Box>
        </Paper>
}

type ArticleListAsPageProps = {
    category: string
}

function ArticleListAsPage(props: ArticleListAsPageProps) {

    const [pageCount, setPageCount] = useState(1)
    const [loaded, setLoaded] = useState(false)
    const [articles, setArticles] = useState(new Array<ArticleModel>())
    const [articleHead, setArticleHead] = useState("")

    useEffect(() => {
        setLoaded(false)
        setArticleHead("")
        articleListService.getItemCount(props.category, (status, resp) => {
            if (status === 200) {
                const pages = Math.ceil(resp.data["count"] / gecsevarConfig.getItemsCountOnArticleListPage())
                setPageCount(pages)
                updateArticles(1)
                setLoaded(true)
            }
        })
        articleCategoryService.get(props.category, (status, resp) => {
            if (status === 200) {
                const category = plainToInstance(ArticleCategoryModel, resp.data)
                if (category.articleId != null) {
                    articleService.getHead(category.articleId, (status, resp) => {
                        if (status === 200) {
                            setArticleHead(ArticleModel.fromJSON(resp.data).content)
                        }
                    })
                }
            }
        })
    }, [props.category]);

    const updateArticles = (value: number) => {
        articleListService.getArticlesAtPage(props.category, value, gecsevarConfig.getItemsCountOnArticleListPage(), (status, resp) => {
            if (status === 200) {
                const temp: Array<ArticleModel> = resp.data.map((value: string) => {
                    return plainToInstance(ArticleModel, value)
                })
                setArticles(temp)
                setLoaded(true)
            }
        })
    }

    return loaded ?
        <Paper elevation={2} sx={{p: 0, m: 0, position: 'relative', overflow: 'auto', minHeight: '500px'}}>
            {
                <Box sx={{p: 1}}>
                    <Grid container margin={0} spacing={0} direction={"row"} justifyContent={"center"} alignItems={"stretch"}>
                        <Grid item xs={0} md={0} lg={0} xl={1}>
                        </Grid>
                        <Grid item xs={11} md={8} lg={8} xl={6}>
                            <motion.div
                                initial={{
                                    opacity: 0,
                                    y: -75
                                }}
                                animate={{
                                    opacity: 1,
                                    y: 0
                                }}
                                transition={{
                                    duration: .5,
                                    delay: 0
                                }}
                                exit={{
                                    opacity: 0,
                                    y: -75
                                }}
                            >
                                <HtmlContent html={articleHead}/>
                            </motion.div>
                        </Grid>
                        <Grid item xs={0} md={0} lg={0} xl={1}>
                        </Grid>
                    </Grid>
                    <Pagination count={pageCount} color={"secondary"} variant={"text"} shape={"rounded"}
                                onChange={(event: React.ChangeEvent<unknown>, value: number) => {
                                    updateArticles(value)
                                }}/>
                    <Grid container margin={0} spacing={0} direction={"row"} justifyContent={"space-between"} alignItems={"stretch"}>
                        {articles.map((item, index) => (
                            <Grid key={index} item xs={12} md={6} lg={4} sx={{verticalAlign: 'center'}}>
                                <div style={{position: "relative", overflow: "hidden"}}>
                                    <AnimatePresence>
                                        <motion.div
                                            initial={{
                                                opacity: 0,
                                                y: 75
                                            }}
                                            animate={{
                                                opacity: 1,
                                                y: 0
                                            }}
                                            transition={{
                                                duration: .5,
                                                delay: (.3 * index)
                                            }}
                                            exit={{
                                                opacity: 0,
                                                y: 75
                                            }}
                                        >
                                            <MaxiNewsCard article={item}/>
                                        </motion.div>
                                    </AnimatePresence>
                                </div>
                            </Grid>
                        ))
                        }
                    </Grid>
                </Box>
            }
        </Paper>
        :
        <></>
}
