import {DrawerMenuModel} from "../models/DrawerMenuModel";
import {GecsevarConfigTheme} from "./GecsevarConfigTheme";

export type HeaderButton = {
    title: string,
    route: string,
    element: string;
}

export enum HTML_HEAD_META {
    META_TITLE,
    META_DESCRIPTION,
    META_NAME,
}

export enum HEADER_COMPONENT {
    LOGO,
    SEARCH,
    COLOR_MODE,
}

export abstract class GecsevarConfig {
    protected gvConfigTheme!: GecsevarConfigTheme

    abstract initialize(initCallback: () => void): void

    abstract getHtmlHead(meta: HTML_HEAD_META): string

    abstract isHeaderComponentVisible(item: HEADER_COMPONENT): boolean

    abstract getHeaderButtons(): HeaderButton[]

    abstract getHeaderButtonElementBy(name: string): JSX.Element

    abstract getMenuTitle(): string

    getContent(path: string | undefined): string {
        return "Override when content is static"
    }

    abstract getContentSideBox(): JSX.Element

    abstract getMenuNavigation(): Array<DrawerMenuModel>

    abstract getSubMenuFor(menuId: string): Array<DrawerMenuModel>

    abstract getDefaultArticleListCategory(): string

    abstract getDashboard(): JSX.Element

    abstract getFooterContent(): JSX.Element

    abstract getCopyrightContent(): JSX.Element

    abstract getItemsCountOnArticleListPage(): number

    getTheme(): GecsevarConfigTheme {
        return this.gvConfigTheme
    }
}

