import React from 'react';
import './App.css';
import {BrowserRouter} from "react-router-dom";
import {MainPage} from "./gecsevar_framework/pages/MainPage";
import {HelmetProvider} from "react-helmet-async";

// https://react-typescript-cheatsheet.netlify.app/docs/basic/setup

export interface SimpleDialogProps {
    open: boolean;
    selectedValue: string;
    onClose: (value: string) => void;
}

const itemData = [
    {
        img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
        title: 'Breakfast',
    },
    {
        img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
        title: 'Burger',
    },
    {
        img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
        title: 'Camera',
    },
    {
        img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
        title: 'Coffee',
    },
    {
        img: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
        title: 'Hats',
    },
    {
        img: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
        title: 'Honey',
    },
    {
        img: 'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6',
        title: 'Basketball',
    },
    {
        img: 'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f',
        title: 'Fern',
    },
    {
        img: 'https://images.unsplash.com/photo-1597645587822-e99fa5d45d25',
        title: 'Mushrooms',
    },
    {
        img: 'https://images.unsplash.com/photo-1567306301408-9b74779a11af',
        title: 'Tomato basil',
    },
    {
        img: 'https://images.unsplash.com/photo-1471357674240-e1a485acb3e1',
        title: 'Sea star',
    },
    {
        img: 'https://images.unsplash.com/photo-1589118949245-7d38baf380d6',
        title: 'Bike',
    },
];

// https://www.freepik.com/free-photos-vectors/4k-wallpaper

// @ts-ignore
function App() {
    return <React.StrictMode>
        <HelmetProvider>
            <BrowserRouter>
                <MainPage/>
            </BrowserRouter>
        </HelmetProvider>
    </React.StrictMode>
}

export default App;
/*
    <>
        <Box height="100vh" display="flex" flexDirection="column" sx={{
            color: 'white',
            background: 'linear-gradient(to top, #00214740, #002147ee), url(./images/natures-beauty-reflected-tranquil-mountain-waters-generative-ai.jpg) no-repeat top center',
        }}>
            <Grid container component={"span"} direction="column" justifyContent="center" alignItems="center"
                  sx={{display: {xs: 'flex', sm: 'flex'}}} marginTop={4} marginBottom={4} spacing={5}>
                <Grid item>
                    <Typography variant={"body1"} fontStyle={"italic"} marginTop={2} marginBottom={2}>
                        we will...
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant={"h5"}>
                        Coming soon - please be patient
                    </Typography>
                </Grid>
                <Grid container
                      direction="column"
                      justifyContent="center"
                      alignItems="flex-end"
                      marginRight={4}
                >
                    <Typography variant={"h6"}>
                        Gecsevar.hu
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    </>
)
}

export default App;

/*
    <React.StrictMode>
        <BrowserRouter>1
            <Welcome/>
        </BrowserRouter>
    </React.StrictMode>

        <ImageList sx={{ width: 500, height: 450 }} cols={3} rowHeight={164}>
            {itemData.map((item) => (
                <ImageListItem key={item.img}>
                    <img
                        srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                        src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                        alt={item.title}
                        loading="lazy"
                    />
                </ImageListItem>
            ))}
        </ImageList>
*/